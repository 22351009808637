<template>
  <div class="app-item app-content">
    <h4>员工观看记录</h4>
    <el-row>
      <el-col :xs="22" :sm="10" :md="10" :lg="10" :xl="10">
        <span>真实姓名：</span>
        <el-select v-model="real_name" clearable placeholder="输入姓名查找" @change="getErecords()" filterable remote
          :remote-method="remoteMethod" :loading="loading">
          <el-option v-for="(item, ind) in employees" :key="ind" :label="item.real_name || item.nick_name"
            :value="item.user_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :xs="22" :sm="10" :md="10" :lg="10" :xl="10">
        <div style="text-align:right">
          <el-button plain @click="open()">导出记录</el-button>
        </div>
      </el-col>
    </el-row>
    <el-table style="width: 100%" :data="tableData">
      <el-table-column prop="real_name" label="员工" align="center"></el-table-column>
      <el-table-column prop="store.depart" label="部门" align="center"></el-table-column>
      <el-table-column prop="store.title" label="职位" align="center"></el-table-column>
      <el-table-column prop="store.role.name" label="角色" align="center"></el-table-column>
      <el-table-column prop="article.title" label="文章" align="center"></el-table-column>
      <el-table-column prop="created_at" label="开始时间" align="center"></el-table-column>

      <el-table-column prop="updated_at" label="结束时间" align="center"></el-table-column>
    </el-table>
    <Page :total="total" @goTo="goTo"></Page>
    <!--导出弹窗--->
    <el-dialog title="导出记录" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="关键字：" prop="key">
            <el-input v-model="ruleForm.key" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item label="开始时间：" prop="starTime">
            <el-date-picker v-model="ruleForm.starTime" type="date" placeholder="选择开始日期" style="width: 100%;"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker v-model="ruleForm.endTime" type="date" placeholder="选择结束日期" style="width: 100%;"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportTest">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import qs from 'qs';
import Page from '../../components/Page.vue';
export default {
  components: { Page },
  data() {
    return {
      total: 0,
      real_name: '',
      tableData: [],
      employees: [],
      dialogVisible: false,
      ruleForm: {
        key: "",
        starTime: "",
        endTime: ""
      },
      rules: {
      },
      loading:false
    }
  },
  methods: {
    open() {
      this.dialogVisible = true
      this.ruleForm = {
        key: "",
        starTime: "",
        endTime: ""
      }
    },
    remoteMethod(q) {
      if (q != "") {
        this.loading = true;
        this.getEmployees(`/api/web/store/users?q=${q}`);
      }
    },
    exportTest() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let { starTime, endTime } = this.ruleForm;
          let stT = 0
          let enT = 0

          if (starTime) {
            stT = new Date(starTime).getTime();
          }

          if (endTime) {
            enT = new Date(endTime).getTime();
          }

          if (enT < stT) {
            this.$message.error('开始时间不能大于结束时间!');
            return false;
          }
          /*** */
          this.$store.dispatch('exportEviewList', { ...this.ruleForm }).then(res => {
            // 创建一个新的 URL 对象
            // let url = window.URL.createObjectURL(res)
            // // 在页面上生成一个a标签并指定href为上面的url,然后模拟点击，以实现自动下载
            // var link = document.createElement('a')
            // document.body.appendChild(link)
            // link.href = url
            // link.download = '观看记录.xlsx'
            // link.click()

            const blob = new Blob([res]);
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = "观看记录.xlsx"; //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
            this.dialogVisible = false;
          })
        } else {
        }
      });

    },
    // 页码
    goTo(page) {
      this.getErecords(page)
    },
    // 获取员工列表
    getEmployees(url = "") {
      this.$store.dispatch('getEmployees', url).then(res => {
        this.employees = res.data;
        this.loading = false
      })
    },
    // 获取员工阅读记录
    getErecords(page) {
      let obj = {}
      if (page) {
        obj = Object.assign({}, obj, { page })
      }
      if (this.real_name) {
        obj = Object.assign({}, obj, { user_id: this.real_name })
      }
      let objString = qs.stringify(obj)
      this.$store.dispatch('getErecords', objString).then(res => {
        console.log(res)
        this.tableData = res.data
        this.total = res.total
      })
    },
    /***
    * 关闭弹窗
    */
    handleClose(done) {
      this.dialogVisible = false;
      done();
    }
  },
  mounted() {
    this.getEmployees()
    this.getErecords()
  }
}
</script>
<style lang="less" scoped>
.app-item {
  .el-row {
    .el-col {
      margin: 0 20px 20px;
      margin-right: 10px
    }
  }
}
</style>
